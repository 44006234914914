import * as React from "react"
import { graphql } from "gatsby"
const Layout = React.lazy(() => import("../components/layout"))
const Intro2 = React.lazy(() => import('../components/sections/Intro2'))
const SobreDisdel = React.lazy(() =>
  import("../components/sections/SobreKymberly")
)
const SolicitarUnaCita = React.lazy(() => import("../components/sections/SolicitarUnaCitaPrincipal"))
const CProductosRecomendados = React.lazy(() => import('../components/sections/ProductosRecomendados'))
const ImagenesSegmentos = React.lazy(() => import("../components/sections/ImagenesSegmentos"))
// const Testimonial2 = React.lazy(() =>
//   import("../components/sections/Testimonial2")
// )
// const Videos = React.lazy(() => import('../components/sections/Videos'))


const ProductosVistos = React.lazy(() => import('../components/ProductosVistos/vistosRecientemente'))
const SeoEmpresa = React.lazy(() => import('../components/SEO/seoEmpresa'))
const Suscribete = React.lazy(() => import("../components/sections/Suscribete"))


const IndexPage = ({ data }) => {
  let {
    allBannerTipo4,
    landingPage,
    SegmentosImagenes,
    ProductosRecomendados,
  } = data
  const banner = allBannerTipo4.edges.map(e => e.node)
  const PProductosRecomendados = ProductosRecomendados.edges.map(e => e.node)
  const SegmentosImagen = SegmentosImagenes.edges.map(e => e.node)
  const landingPages = landingPage.edges.map(e => e.node.Paginas)
  const [LandingPages] = landingPages.map(e => e.LandingPages)
  const isSSR = typeof window === "undefined"

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Layout>
            <div className="landing">
              <SeoEmpresa
                title="Disdel 3M Guatemala"
                keywords={[
                  "Alfombra-3M",
                  "Limpieza 3m",
                  "distribuidora de productos 3M",
                  "Scotch-Brite 3M",
                  "Alfombra 3M",
                  "Cinta Multiusos 3M",
                  "Equipo de Proteccion Personal 3M",
                  "Herramientas profesionales de limpieza 3M",
                  "Quimicos 3M"
                ]}
                image={
                  "https://disdelsa.com/imagenes/BannerImagen-img2146-20-7-2021-70122.png"
                }
              />
              <Intro2 banner={banner} />
              <ImagenesSegmentos SegmentosImagen={SegmentosImagen} />
              <CProductosRecomendados Recomendados={PProductosRecomendados} />
              <SolicitarUnaCita />
              <SobreDisdel LandingPages={LandingPages} />
              {/* <Testimonial2 LandingPages={LandingPages} /> */}
              {/* <Videos /> */}
              {/* <ProductosVistos /> */}
              <Suscribete />

            </div>
          </Layout>
        </React.Suspense>
      )}
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allBannerTipo4: allBannersJsonJson(filter: { IdTipoBanner: { in: 4 } }) {
      edges {
        node {
          Imagen
          BannerImagenMovil
        }
      }
    }

    landingPage: allDataJson {
      edges {
        node {
          Paginas {
            LandingPages {
              EntityID
              IdDivision
              IdLandingPage
              IdCompania
              TipoLanding
              Titulo
              CuerpoPagina
              CuerpoPajinaMovil
            }
          }
        }
      }
    }
    SegmentosImagenes: allMenuJsonJson {
      edges {
        node {
          NombreSegmento
          Imagen
          SegmentoAux
        }
      }
    }
    ProductosRecomendados:allProductosRecomendadosJson(filter: {Tipo: {in: 7}}) {
      edges {
        node {
          id
          IdProducto
          IdRelacion
          Descripcion
          Imagen
          BaseUnidad
          ItemCodeAux
          BaseDefault
          BaseFardo
          Fardo
          Categoria
          IdCategoria
          Unidad
          PrecioIVA
          MultiploFardo
          MultiploUnidad
        }
      }
    }
  }
`
